// This script removes screen jerk when swipe back on safari

const handleTouchMove = (event: TouchEvent, xStart: number, yStart: number) => {
  const xDiff = xStart - event.touches[0].pageX
  const yDiff = yStart - event.touches[0].pageY

  // Prevent horizontal swipe
  if (xDiff > 20 && Math.abs(xDiff) > Math.abs(yDiff)) {
    event.preventDefault()
  }
}

const newHandleTouchMove =
  (xStart: number, yStart: number) => (event: TouchEvent) => {
    handleTouchMove(event, xStart, yStart)
  }

export const addTouchStartListener = () => {
  document.addEventListener(
    "touchstart",
    (startEvent) => {
      // Ignore multi-touch gestures
      if (startEvent.touches.length > 1) {
        return
      }

      const xStart = startEvent.touches[0].pageX
      const yStart = startEvent.touches[0].pageY

      // Attach the touchmove event listener
      document.addEventListener(
        "touchmove",
        newHandleTouchMove(xStart, yStart),
        {
          passive: false,
        },
      )
    },
    { passive: false },
  )
}
